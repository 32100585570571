import React from 'react'
import Header from './Header'

interface ILayout {
    children: React.ReactNode
}

const Layout = ({ children }: ILayout) => {


    return (
        <>
            <Header />
            <main>
                <div className="container mr-auto px-6 py-10 max-w-5xl">
                    {children}
                </div>
            </main>
        </>
    )
}

export default Layout