import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Header = () => {


    return (
        <header>
            <div id="logo">
                <Link to="/" className="text-white">
                    <h1>Meagan & Alex</h1>
                </Link>
            </div>

            <StaticImage id="headerSelfie" layout={`constrained`} src={`../images/IMG_0605_cropped.png`} width={200} placeholder={`blurred`} alt="Meagan and Alex photo at Walt Disney World Epcot park" />
        </header>
    )
}

export default Header